import * as Sentry from "@sentry/vue";
import VueLogger from "vue-logger-plugin";

export enum ErrorTypeEnum {
  API_ERROR = "API_ERROR",
}

export interface LoggerInterface {
  debug(...args: any[]): void;
  info(...args: any[]): void;
  warn(...args: any[]): void;
  error(...args: any[]): void;
}

import Vue from "vue";

export class VueLoggerAdapter implements LoggerInterface {
  debug(...args: any[]): void {
    Vue.prototype.$log.debug(...args);
  }
  info(...args: any[]): void {
    Vue.prototype.$log.info(...args);
  }
  warn(...args: any[]): void {
    Vue.prototype.$log.warn(...args);
  }
  error(...args: any[]): void {
    Vue.prototype.$log.error(...args);
    // sentry로 로그 전송
    Sentry.captureException({
      name: ErrorTypeEnum.API_ERROR,
      message: args.join("|"),
    });
  }
}

// Logger configuration options
const options = {
  enabled: true,
  level: process.env.VUE_APP_ENV !== "production" ? "debug" : "error",
  separator: "|",
  showConsoleColors: true,
};

Vue.use(VueLogger, options);

Vue.config.productionTip = false;

const logger: LoggerInterface = new VueLoggerAdapter();

Vue.prototype.$logger = logger;
